@import 'styles/breakpoint-mixins.scss';

.filterOptions {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down('xs') {
    flex-direction: column;
  }
}

.filterInput {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  @include media-breakpoint-down('md') {
    flex-direction: column;
  }
}

.reportFilterInput {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  padding: 10px;
  @include media-breakpoint-down('sm') {
    flex-direction: column;
  }
}

.multiSelect {
  height: 30px;
  max-width: 14%;

  @include media-breakpoint-down('lg') {
    max-width: 14%;
  }
  @include media-breakpoint-down('md') {
    max-width: 100%;
  }
  @include media-breakpoint-down('sm') {
    max-width: 100%;
  }
}

.multiSelectReports {
  height: 40px;
  max-width: 25%;
  @include media-breakpoint-down('lg') {
    max-width: 25%;
  }
  @include media-breakpoint-down('md') {
    max-width: 25%;
  }
  @include media-breakpoint-down('sm') {
    max-width: 100%;
  }
}

.filterInputDealerShipuser {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
}
