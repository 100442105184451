@import 'styles/breakpoint-mixins.scss';

.cardContainer {
  height: 107px;
  margin: 10px 0;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.1);
  border-radius: 5px;
}

.cardContent {
  padding: 16px 15px 0;
  .cardheading {
    padding-left: 5px;
    font-weight: 400;
    font-size: 16px;
  }
}

.cardNumberIcon {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  .cardNumber {
    font-weight: 700;
    font-size: 32px;
    color: #444444;
  }
}

.customCard {
  display: flex;
  justify-content: space-between;
}
