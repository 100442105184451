@import 'styles/breakpoint-mixins.scss';

.paper {
  width: 100%;
  margin: 20px;
  padding: 5%;
  @include media-breakpoint-down('sm') {
    margin: 0px;
  }
}
.pageContainer {
  background-color: transparent;
}
.contentContainer {
  border: 1px solid #d0d0d0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21);
  box-sizing: border-box;
  border-radius: 6px;
  margin: 30px 0;
  width: 100%;
}
.consentLinks {
  margin: 15px 30px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.footerIcons {
  display: flex;
  justify-content: flex-end;
}
.buttonStyle {
  border-radius: 56px;
  padding: 0 40px;
  @include media-breakpoint-down('sm') {
    width: 100%;
  }
}
.thankyouContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 94%;
  height: 94%;
  margin: 3%;
  position: fixed;
}
.links {
  display: flex;
  flex-direction: column;
  padding: 0 30px 20px;
}

.links > a {
  color: #661c69;
  text-decoration: none;
  display: flex;
  width: fit-content;
}
.links > a > span {
  padding-left: 10px;
}
.expiryImage > img {
  width: 70%;
  @include media-breakpoint-up('sm') {
    width: 75%;
    height: auto;
  }
  @include media-breakpoint-up('md') {
    width: 80%;
  }
  @include media-breakpoint-up('lg') {
    width: 90%;
  }
}
.expiryImage {
  margin: auto;
  padding: 20px 0;
  @include media-breakpoint-down('sm') {
    flex-direction: column;
  }
}
.thankScreenIcon {
  @include media-breakpoint-down('sm') {
    width: 15%;
  }
}
.thankyouScreen {
  font-size: 4rem !important;
  font-weight: 700 !important;
  padding: 25px 0;
  @include media-breakpoint-down('sm') {
    padding: 10px 0;
    font-size: 3rem !important;
  }
}
.thankyouScreenContent {
  font-weight: 700;
  width: 40%;
  text-align: center;
  @include media-breakpoint-down('sm') {
    width: 100%;
  }
}
.imageContent {
  position: absolute;
  top: 23%;
  bottom: 50%;
  left: 60%;
  padding: 10px;
  width: 25%;
  word-break: break-word;
  @include media-breakpoint-up('md') {
    left: 62%;
    width: 28%;
  }
  @include media-breakpoint-up('lg') {
    left: 65%;
    width: 32%;
  }
  @include media-breakpoint-down('sm') {
    width: 70%;
    position: unset;
    height: auto;
    margin: auto;
  }
}

.imageText {
  font-size: 15px !important;
  @include media-breakpoint-up('sm') {
    font-size: 1rem !important;
  }
  @include media-breakpoint-up('md') {
    font-size: 18px !important;
  }
  @include media-breakpoint-up('lg') {
    font-size: 20px !important;
  }
}
.imageHeading {
  font-size: 25px !important;
  padding: 0 0 20px 0;

  @include media-breakpoint-up('sm') {
    font-size: 26px !important;
  }
  @include media-breakpoint-up('md') {
    font-size: 26px !important;
  }
  @include media-breakpoint-up('lg') {
    font-size: 30px !important;
  }
}
.invitationHeading {
  margin: 0 0 20px 0;
  justify-content: flex-start;
  text-align: left;
  @include media-breakpoint-down('sm') {
    justify-content: center;
    text-align: center;
  }
}
