.avatarImage {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: top center;
  border-radius: 50%;
  border: 3px solid #909090;
}
.avatarPosition > div {
  display: flex;
  justify-content: center;
}

.uploadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
}
.uploadButton > p {
  font-size: 20px !important;
  margin-right: 10px;
}
