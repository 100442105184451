.imgPadding {
  font-weight: 400;
  font-size: 14px !important;
  img {
    padding-right: 10px;
  }
}
.heading {
  border-bottom: 1px solid #e37617;
  color: #000;

  padding: 0 0 10px 0;
  margin: 0 0 16px 0;
  width: 100%;
  text-align: left;
}
.settingHeading {
  display: flex;
  justify-content: space-between;
  padding: 25px 0 14px 0;
  background: white;
  width: 100%;
}
.headerIcons {
  min-width: auto;
  padding: 0 10px;
}
.portalHeading {
  flex-grow: 1;
  margin-left: 7px;
}
.verticalDivider {
  margin: 15px 20px 15px 10px;
  border-right-width: medium;
}
.avatarName {
  font-weight: 700;
  font-size: 12px;
  padding: 2.5px 0;
}
.avatarSubName {
  font-weight: 400;
  font-size: 10px;
  color: #909090;
}
.avatarBorder {
  border: 3.5px solid #dfe0eb;
  padding: 3px;
  border-radius: 50%;
}
.notificationCard {
  padding: 10px 18px 10px 8px;
  background: rgba(144, 144, 144, 0.05);
  border-bottom: 1px solid rgba(144, 144, 144, 0.3);
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.notificationTime {
  font-weight: 400;
  font-size: 11px;
  padding-top: 10px;
  color: #909090;
}
.NotificationTopPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notificationBody {
  padding: 0 15px 0 20px;
}
.notificationHeading,
.notificationPopupHeading {
  font-weight: 700;
  font-size: 14px;
}
.notificationPopupHeading {
  padding-bottom: 10px;
}
.noNotificationContainer {
  text-align: center;
  margin-top: 50px;
}
.noNotificationHeading {
  font-weight: 700;
  font-size: 20px;
}
.noNotificationBody {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 30px;
}

.snackBarContainer {
  border: 1px solid #e37617;
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.1);
  border-radius: 8px;
  background: #fff;
  color: #000;
  width: 430px;
  height: 230px;
  padding: 0 20px;
}
.snackBarHeading {
  font-weight: 700;

  padding-left: 13px;
}
.snackBarContent {
  font-weight: 400;

  padding-bottom: 8px;
}
