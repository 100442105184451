@import 'styles/breakpoint-mixins.scss';
@import 'styles/variables.scss';

.skipLink {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skipLink:focus {
  left: 150px;
  top: 10px;
  width: auto;
  height: auto;
  overflow: visible;
  background-color: #fff;
  z-index: 1000;
  border: 2px solid #000;
  padding: 10px;
}
.contentContainer {
  min-height: 90vh;
}
.pdfviewerPaper {
  max-width: unset;
}
.offerSlider {
  max-width: 800px;
}

.datePicker {
  width: 100%;
  height: 30px !important;
}

.tooltip {
  background-color: white;
  border: 1px solid #e37617;
  color: black;
  width: 750px;
  max-width: 100%;
}
.highlighted {
  background-color: #f2f2f2;
  h6,
  button,
  p {
    font-weight: 700;
  }
}
.infoAlert {
  margin-top: 8px;
  background-color: #f1f6fd;
  border-left: 5px solid #0052ea;
  border-radius: 0;
  align-items: center;
  color: #000000;
  font-size: 14px !important;
  margin: 0 25px;
}

.tooltipArrow {
  margin-left: -17px !important;
  height: 20px !important;
  width: 16px !important;
}

.tooltipArrow::before {
  border-left: 1px solid #e37617;
  border-bottom: 1px solid #e37617;
  background-color: transparent;
}
.customToggleButton {
  border: 1px solid #e37617;
  border-radius: 5px;
  background-color: transparent;
  height: 120px;
  width: 120px;
}
.customToggleButton:focus,
.customToggleButton:active,
.customToggleButton:hover {
  background-color: rgba(227, 118, 23, 0.05);
}
.customToggleButton:disabled {
  border: 1px solid #e37617;
  opacity: 0.4;
}
.customToggleGroup:disabled {
  border: 1px solid #e37617;
  opacity: 0.4;
}
.customToggleGroup {
  max-height: none;
}
.customToggleGroup:not(:first-of-type) {
  border-left: 1px solid #e37617;
}
.paperBg {
  padding: 20px;
  width: 100%;
  @include media-breakpoint-up('sm') {
    width: 70%;
    margin: 40px;
  }
}
.paperBg:has(.home, .payCalc) {
  width: 100%;
  margin: none;
  padding: none;
}
.paperBg:has(
    .lookup,
    .equipAdjustment,
    .payCalcPaper,
    .dummyHome,
    .infoRequestPaper,
    .viewApplication,
    .profileView
  ) {
  @include media-breakpoint-up('sm') {
    width: 100%;
    margin: 0;
  }
}
.paperBg:has(.camContainer),
.infoRequestPaper:has(.camContainer) {
  background-color: transparent;
}

.multilineInputComment {
  &:focus {
    border: 1px solid orange;
  }
  border-radius: 4px;
  padding: 10px;

  font-family: Arial, Helvetica, sans-serif;
  resize: none;
}
.lookup {
  display: flex;
  justify-content: center;
  padding: 20px;
  min-height: 80vh;
  gap: 40px;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 6%;
}
.profileView {
  display: flex;
  padding: 20px;
  min-height: 80vh;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 6%;
}

.childBorderLine > div,
.borderTable {
  border-bottom: 1px solid #f2f2f2;
}

.childBorderLine {
  h6,
  p {
    overflow-wrap: break-word;
    font-size: 14px !important;
  }
}
.tableOutline {
  border: 1px solid #dadada;
  p {
    font-size: 14px !important;
  }
}

.equipAdjustment {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  margin-top: 20px;

  @include media-breakpoint-down('md') {
    flex-direction: column;
  }
}
.uploadContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  max-height: 100%;
  // max-width: 340px;
  background-image: url('../assets/svg//border.svg');
  border-radius: 6px;
}

.footer {
  min-height: 48px;
  height: 48px;
}

.capitalize::first-letter {
  text-transform: capitalize;
}

.heading {
  border-bottom: 1px solid #909090;
  color: #e37617;

  padding: 10px 0;
  margin: 16px 0;
  width: 100%;
  text-align: left;
}

.customContainer {
  padding: 4px 8px;
  align-items: center;
  @include media-breakpoint-down('sm') {
    padding: 8px 4px;
  }
}
.numberFormat {
  width: 100%;
}

.vinInput {
  min-width: 70%;
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
}
.listItemBtnClose {
  min-height: 48;
  justify-content: initial;
  padding: 0 20px;
}
.listItemBtnOpen {
  min-height: 48;
  text-align: center;
  display: block;
  padding: 0;
}

.hideTotalCheckBox {
  div[role='row']:last-of-type {
    background-color: $secondary;
    color: white;
    div[data-field='__check__'] {
      visibility: hidden;
    }
  }
}
.cellContent {
  white-space: normal;
}
.totalAlign {
  .totalHeading {
    min-width: 250px;
    max-width: 250px;
    margin: 10px;
  }
  p {
    min-width: 100px;
    max-width: 100px;
  }
}
.customNumberFormat {
  margin: 10px 0;
}
.creditLifeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  .box1 {
    width: 50%;
    margin-left: 16px;
  }
  .box2 {
    display: flex;
    width: 50%;
  }
}
.modalIconBtn {
  float: right;
  padding: 0;
}
.resultBtn {
  padding: 40px 0;
  text-align: center;
}
.customSelectError {
  // width: 47%;
  // float: right;
  color: #d32f2f;
}
.customUpload {
  border: 2px solid rgb(212, 212, 212);
  padding: 10px;
  width: 100%;
  border-radius: 5px;
}
.resultsBox {
  padding: 8px 0;
  border: 1px solid grey;
}
.viewApplication {
  // padding: 10px 20px 20px 20px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 20px 0;
}
.infoRequestPaper {
  padding: 10px 20px 20px 20px;
  min-height: 80vh;
  gap: 8px;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 6%;
}
.payCalcPaper {
  display: flex;
  justify-content: center;
  padding: 10px 20px 20px 20px;
  min-height: 80vh;
  gap: 8px;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 6%;
}

.radioLabel {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: max-content;
}
.customToggleText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  margin: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tableHeadingList {
  border: none;
  width: 60%;
  font-size: 14px !important;
  padding: 8px;
}

.currencyValueNoCaps {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #661c69;
  border: none;
}

.currencyValue {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #661c69;
  border: none;
  text-transform: capitalize;
}
.tableContainer {
  border: 1px solid #dadada;
  background-color: #fff;
}
.TableHeading {
  font-weight: 700;
  background: #f2f2f2;
  border: 1px solid #dadada;
  font-size: 14px !important;
  border: none;
  padding: 8px;
}
.tableHeadingApplications > th {
  font-weight: 700;
  min-width: 150px;
  text-align: left;
}

.reviewAndSubmit {
  .collapsableDiv {
    padding: 0px 60px;
  }
  .currencyValue {
    font-size: 14px !important;
    font-weight: 700 !important;
    color: #661c69;
    border: none;
    text-transform: none;
  }

  .reviewSubmitFinance {
    .tableHeadingList {
      padding: 0px;
      padding-bottom: 10px;
    }
    .currencyValue {
      padding: 0px;
      padding-bottom: 10px;
    }
  }
}

.logoutStyling {
  font-size: '16px';
  min-width: '90px';
  height: '40px';
  font-weight: 700;
}
.collateralDialog {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 31px;
  justify-content: center;
  @include media-breakpoint-down('md') {
    gap: 16px;
  }
  @include media-breakpoint-up('md') {
    gap: 27px;
  }
}
.successAlert {
  border: solid rgba(18, 138, 8, 0.5);
  border-width: 1px 1px 1px 5px;

  background: rgba(18, 138, 8, 0.05);
  border-radius: 0;
  color: #000000;
}
